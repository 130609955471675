import Vue from 'vue/dist/vue.esm.js';
import TurbolinksAdapter from 'vue-turbolinks'
import {globalMixin} from "../mixins/global";

Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById("vue-diagnose-new") ||
    document.getElementById("vue-diagnose-create")
  if (element) {
    window.vueLoaded = true

    const app = new Vue({
      el: element,
      mixins: [globalMixin],
      components: {
      },
      data: () => {
        return {
          hasMentalDisorder: null,
          hasLoanArrears: null,
          hasGuaranteeCompanyArrears: null
        }
      },
      created() {
      },
      async mounted() {
      },
      methods: {
      }
    })
  }
})
