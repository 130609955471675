export const estatePropertyRoomsMixin = {
  data: () => {
    return {
      showPropertyDetailTab: "property",
      slickOptions: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        asNavFor: '#slider-nav',
      },
      slickOptionsNav: {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        asNavFor: '#slider-for',
      },
      totalSlideCount: 0,
      currentSlideCount: 0,
      showImageModal: false,
      targetSlideSrc: ""
    }
  },
  created: function () {},
  mounted() {
    const slider = this.$refs['slider-for'];
    slider.$on('afterChange', this.handleAfterChange);
  },
  methods: {
    handleInit(event, slick) {
      this.totalSlideCount = slick.slideCount
      this.currentSlideCount = slick.currentSlide + 1
    },
    handleAfterChange(event, slick, currentSlide) {
      this.currentSlideCount = currentSlide + 1
    },
    handleClick($event) {
      this.showImageModal = true
      if ($event.target.currentSrc) {
        this.targetSlideSrc = $event.target.currentSrc
      } else {
        this.targetSlideSrc = $event.target.childNodes[0].currentSrc
      }
    }
  }
}
