// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
// 画像関連の名前解決
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import Vue from 'vue/dist/vue.esm.js';
import TurbolinksAdapter from 'vue-turbolinks'
import {globalMixin} from "../mixins/global";
import VueScrollTo from 'vue-scrollto'

Vue.use(TurbolinksAdapter)
Vue.use(VueScrollTo, { duration: 500 })

import '../stylesheets/style.scss'

// コントローラー毎にマウントするvueインスタンス
import "../controllers/home_index"
import "../controllers/estateProperties_index"
import "../controllers/rooms_show"
import "../controllers/blogs_index"
import "../controllers/estatePropertyRooms_show"
import "../controllers/lp_estateProperties_new"
import "../controllers/lp_public_assistances_new"
import "../controllers/diagnose_new"

// vueインスタンスがマウントされているかどうかの判定を初期化
document.addEventListener('turbolinks:render', () => {
  window.vueLoaded = false
})

document.addEventListener('turbolinks:load', () => {

  // 読み込むJSがない場合に呼び出されるデフォルトのvueインスタンス
  if (!window.vueLoaded) {
    const app = new Vue({
      el: ".vue-default-app",
      mixins: [globalMixin],
      components: {},
      data: () => {
        return {}
      },
      created() {
      },
      methods: {}
    })
  }

})
