import Vue from 'vue/dist/vue.esm.js';
import TurbolinksAdapter from 'vue-turbolinks'
import {globalMixin} from "../mixins/global";

Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById("vue-blogs-index")
  if (element) {
    window.vueLoaded = true

    const app = new Vue({
      el: element,
      mixins: [globalMixin],
      components: {},
      data: () => {
        return {
          showPostCategoriesTab: "recent",
        }
      },
      created() {
        console.log('pageData', pageData);
        this.showPostCategoriesTab = pageData.category ? pageData.category : 'recent'
      },
      methods: {}
    })
  }
})
