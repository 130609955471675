import Vue from 'vue/dist/vue.esm.js';
import TurbolinksAdapter from 'vue-turbolinks'
import {globalMixin} from "../mixins/global";
Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById("vue-estate_properties-index")
  if (element) {
    window.vueLoaded = true

    const app = new Vue({
      el: element,
      mixins: [globalMixin],
      components: {},
      data: () => {
        return {
          showPropertyRefineModal: false,
        }
      },
      created() {
      },
      methods: {
      }
    })
  }
})
