import Vue from 'vue/dist/vue.esm.js';
import TurbolinksAdapter from 'vue-turbolinks'
import { globalMixin } from '../mixins/global'
import {estatePropertyRoomsMixin} from "../mixins/estate_property_rooms";
import Slick from "vue-slick";
import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'
import '../../../node_modules/slick-carousel/slick/ajax-loader.gif'

Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById("vue-rooms-show")
  if (element) {
    window.vueLoaded = true

    const app = new Vue({
      el: element,
      mixins: [
        globalMixin,
        estatePropertyRoomsMixin
      ],
      components: { Slick },
      data: () => {
        return {
        }
      },
    })
  }
})
