export const globalMixin = {
  data: () => {
    return {
      // global
      showHeaderMenu: false,
      scrollableElem: null,
      showFooterMenuVisible: false
    }
  },
  created: function () {
    this.scrollableElem = document.getElementsByTagName('body')[0]
    this.showFooterMenuVisible = false
  },
  mounted() {
    this.scrollableElem.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    this.scrollableElem.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    helloMixin: function () {
      console.log('hello from mixin!')
    },
    handleScroll() {
      if (!this.showFooterMenuVisible) {
        const scrollPosition = this.scrollableElem.scrollTop;
        const viewportHeight = window.innerHeight;

        const ignoreUrls = [
            'public_assistance',
            'diagnose',
            'lp/estate_properties',
        ]
        const currentUrl = window.location.href;
        const isIgnore = ignoreUrls.some(url => currentUrl.includes(url));

        this.showFooterMenuVisible = !isIgnore && scrollPosition > viewportHeight;
      }
    }
  }
}
