import Vue from 'vue/dist/vue.esm.js';
import TurbolinksAdapter from 'vue-turbolinks'
import {globalMixin} from "../mixins/global";
import Slick from 'vue-slick';
import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'
import '../../../node_modules/slick-carousel/slick/ajax-loader.gif'

Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById("vue-home-index")
  if (element) {
    window.vueLoaded = true

    const app = new Vue({
      el: element,
      mixins: [globalMixin],
      components: { Slick },
      data: () => {
        return {
          slickOptions: {
            dots: true,
            slidesToShow: 1,
          },
          slickOptionsBlog: {
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
          },
          slickOptionsBlogSp: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
          },
        }
      },
      created() {},
      methods: {
        next() {
          this.$refs.slick.next();
        },
        prev() {
          this.$refs.slick.prev();
        },
      }
    })
  }
})
