export const inputChangeClass = {
  data: () => {
    return {}
  },
  created: function () {
    const formElements = document.getElementById("form").elements
    Array.from(formElements).forEach(element => this.changeInputClass(element))
  },
  methods: {
    async handleChangeClass(e) {
      const element = e.target
      this.changeInputClass(element)
    },
    changeInputClass(element) {
      if (element.type === "radio") {
        const form = document.getElementById("form")
        const radioNodeList = form[element.name]

        // ラジオボタンのアクティブを初期化
        radioNodeList.forEach(element => element.classList.remove("is-active"))

        if (radioNodeList.value !== '' && radioNodeList.value === element.value ) {
          element.classList.add('is-active')
          document.getElementById(element.name).classList.add('is-active')
        }
      } else {
        if (element.value) {
          element.classList.add('is-active')

          //必須ラベル
          if (document.getElementById(element.name)) {
            document.getElementById(element.name).classList.add('is-active')
          }
        } else {
          element.classList.remove('is-active')

          //必須ラベル
          if (document.getElementById(element.name)) {
            document.getElementById(element.name).classList.remove('is-active')
          }
        }
      }
    }
  }
}
